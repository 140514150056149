/**
 * Makes an Instagram URL from a username.
 * @param username Username.
 * @returns An Instagram URL.
 */
export const instagram = (username: string): `https://${string}` => `https://www.instagram.com/${username}/`;

/**
 * Makes a LinkedIn URL from a username.
 * @param username Username.
 * @returns A LinkedIn URL.
 */
export const linkedin = (username: string): `https://${string}` => `https://ch.linkedin.com/in/${username}`;
